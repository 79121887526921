function animateValue(id, start, end, duration) {
  var range = end - start;
  var current = start;
  var increment = end > start ? 1 : -1;
  var stepTime = Math.abs(Math.floor(duration / range));
  var obj = document.getElementById(id);
  var timer = setInterval(function () {
    current += increment;
    obj.innerHTML = current;
    if (current == end) {
      clearInterval(timer);
    }
  }, stepTime);
}

function elementInViewport2(el) {
  var top = el.offsetTop;
  var left = el.offsetLeft;
  var width = el.offsetWidth;
  var height = el.offsetHeight;

  while (el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }

  return (
    top < window.pageYOffset + window.innerHeight &&
    left < window.pageXOffset + window.innerWidth &&
    top + height > window.pageYOffset &&
    left + width > window.pageXOffset
  );
}

var sdgList = {
  1: {
    description: "No poverty",
    xAxisBackgroundColour: "#E32840",
    count: 7,
    barHeight: 35,
  },
  2: {
    description: "Zero hunger",
    xAxisBackgroundColour: "#DCA545",
    count: 4,
    barHeight: 20,
  },
  3: {
    description: "Good health and wellbeing",
    xAxisBackgroundColour: "#4F9E3E",
    count: 20,
    barHeight: 100,
  },
  4: {
    description: "Quality Education",
    xAxisBackgroundColour: "#C31D32",
    count: 13,
    barHeight: 65,
  },
  5: {
    description: "Gender Equality",
    xAxisBackgroundColour: "#FC3D2E",
    count: 5,
    barHeight: 25,
  },
  6: {
    description: "Clean Water and Sanitation",
    xAxisBackgroundColour: "#1CAED7",
    count: 8,
    barHeight: 40,
  },
  7: {
    description: "Affordable and Clean Energy",
    xAxisBackgroundColour: "#FBC230",
    count: 2,
    barHeight: 10,
  },
  8: {
    description: "Decent Work and Economic Growth",
    xAxisBackgroundColour: "#A01C44",
    count: 4,
    barHeight: 20,
  },
  9: {
    description: "Industry, Innovation, and Infrastructure",
    xAxisBackgroundColour: "#FB6A33",
    count: 3,
    barHeight: 15,
  },
  10: {
    description: "Reducing Inequality",
    xAxisBackgroundColour: "#DB1C68",
    count: 6,
    barHeight: 30,
  },
  11: {
    description: "Sustainable Cities and Communities",
    xAxisBackgroundColour: "#FB9D37",
    count: 3,
    barHeight: 15,
  },
  12: {
    description: "Responsible Consumption and Production",
    xAxisBackgroundColour: "#BE8A38",
    count: 4,
    barHeight: 20,
  },
  13: {
    description: "Climate Action",
    xAxisBackgroundColour: "#417D47",
    count: 5,
    barHeight: 25,
  },
  14: {
    description: "Life Below Water",
    xAxisBackgroundColour: "#1D98D7",
    count: 4,
    barHeight: 20,
  },
  15: {
    description: "Life On Land",
    xAxisBackgroundColour: "#5ABE38",
    count: 3,
    barHeight: 15,
  },
  16: {
    description: "Peace, Justice, and Strong Institutions",
    xAxisBackgroundColour: "#0D699B",
    count: 4,
    barHeight: 20,
  },
  17: {
    description: "Partnerships for the Goals",
    xAxisBackgroundColour: "#1C4969",
    count: 7,
    barHeight: 35,
  },
};

var counters = {
  numberOfFoundations: 392,
  numberOfGrantees: 581,
  numberOfInitiatives: 1078,
  numberOfInitiativeReports: 2074,
};

let request = new XMLHttpRequest();

//var requestURL = 'https://cors-anywhere.herokuapp.com/https://found-aid.force.com/foundgood/services/apexrest/platformMetrics';
//var requestURL = 'https://found-aid.force.com/foundgood/services/apexrest/platformMetrics';
var requestURL =
  "https://foundgood.my.site.com/foundgood/services/apexrest/platformMetrics";
//var requestURL = 'http://localhost:8888/found-good/js/platformMetrics.json';

//request.responseType = 'json';
request.open("GET", requestURL);
request.send();
request.onload = function () {
  const numbersString = request.response; // get the string from the response
  const numbersJson = JSON.parse(numbersString); // convert it to an object
  var initiativesPerSDG = numbersJson.initiativesPerSDG;

  if (numbersJson.numberOfFoundations > counters.numberOfFoundations) {
    counters.numberOfFoundations = numbersJson.numberOfFoundations;
  }

  if (numbersJson.numberOfGrantees > counters.numberOfGrantees) {
    counters.numberOfGrantees = numbersJson.numberOfGrantees;
  }

  if (numbersJson.numberOfInitiatives > counters.numberOfInitiatives) {
    counters.numberOfInitiatives = numbersJson.numberOfInitiatives;
  }

  if (
    numbersJson.numberOfInitiativeReports > counters.numberOfInitiativeReports
  ) {
    counters.numberOfInitiativeReports = numbersJson.numberOfInitiativeReports;
  }

  document.getElementById("number-foundations").innerHTML =
    counters.numberOfFoundations;
  document.getElementById("number-grantees").innerHTML =
    counters.numberOfGrantees;
  document.getElementById("number-initiatives").innerHTML =
    counters.numberOfInitiatives;
  document.getElementById("number-reports").innerHTML =
    counters.numberOfInitiativeReports;

  /* Render SDG graph */

  // Loop through the SDG counts and find the max value
  function getHeightUnit(initiativesPerSDG) {
    var sortedSdgs = [];
    for (var key in initiativesPerSDG)
      sortedSdgs.push([key, initiativesPerSDG[key]]);

    sortedSdgs.sort(function (a, b) {
      a = a[1];
      b = b[1];

      return a < b ? -1 : a > b ? 1 : 0;
    });

    var maxSdgCount = sortedSdgs[sortedSdgs.length - 1][1];
    return 88 / maxSdgCount;
  }

  //Set default bar height unit count of 1 = 5% of bar height as standard
  var barHeightUnit = 5;

  //If there aren't 17 SDG's on the API something is wrong so just use default object values
  if (Object.keys(initiativesPerSDG).length == 17) {
    var barHeightUnit = getHeightUnit(initiativesPerSDG);
    for (let key in sdgList) {
      if (sdgList[key].count < initiativesPerSDG[key]) {
        sdgList[key].count = initiativesPerSDG[key];
      }

      sdgList[key].barHeight = sdgList[key].count * barHeightUnit;
    }
  } else {
    console.log("SDGS not equal to 17");
  }

  // Test to see if the browser supports the HTML template element by checking
  // for the presence of the template element's content attribute.
  if ("content" in document.createElement("template")) {
    // Find the graph container to insert the graph items
    var graphContainer = document.querySelector("#graph-container");

    // Instantiate the graph item template
    var graphItemTemplate = document.querySelector("#graph-items");

    //Loop through all of the SDG's and create graph items for each one using the template
    for (let key in sdgList) {
      var clone = graphItemTemplate.content.cloneNode(true);

      var graphBarLabel = clone.querySelector(".bar-label");
      graphBarLabel.innerHTML = sdgList[key].count;

      var graphXAxisLabel = clone.querySelector(".x-axis-label");
      graphXAxisLabel.innerHTML = key;
      graphXAxisLabel.style.backgroundColor =
        sdgList[key].xAxisBackgroundColour;

      var graphBar = clone.querySelector(".bar");
      graphBar.style.height = String(sdgList[key].barHeight).concat("%");

      var graphBarToolTip = clone.querySelector(".x-axis-tooltip");
      //graphBarToolTip.innerHTML = sdgList[key].description;

      graphContainer.appendChild(clone);
    }
  } else {
    // Find another way to add the rows to the table because
    // the HTML template element is not supported.
  }

  /* Check for new values on the API then set the 4 counters wih the value */
};

//when
var fireEvent = true;
$(window).scroll(function () {
  var element = "#counters-container";
  var top_of_element = $(element).offset().top;
  var bottom_of_element = $(element).offset().top + $(element).outerHeight();
  var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
  var top_of_screen = $(window).scrollTop();

  if (bottom_of_screen > top_of_element && top_of_screen < bottom_of_element) {
    if (fireEvent) {
      fireEvent = false;
      var animationDuration = 1000;
      animateValue(
        "number-foundations",
        0,
        counters.numberOfFoundations,
        animationDuration
      );
      animateValue(
        "number-grantees",
        0,
        counters.numberOfGrantees,
        animationDuration
      );
      animateValue(
        "number-initiatives",
        0,
        counters.numberOfInitiatives,
        animationDuration
      );
      animateValue(
        "number-reports",
        0,
        counters.numberOfInitiativeReports,
        animationDuration
      );
    }
    //		//
  }
});
